export default {
    data() {
        return {
        }
    },
    methods: {
        closePopup() {
            this.$emit('closePopup')
        },
        confirmUnite() {
            this.$emit('confirmUnite')
            this.$emit('closePopup')
        },
    }
}